<template>
  <div>
    <Navbar class="nav-z-idx"/>
    <div class="container-v3">
      <div class="center-content-v3">
        <div class="maintenance-v3">
          <img src="@/assets/images/maintenance.svg" alt="">
          <p class="title-maintenance-v3">We’re under maintenance</p>
          <p class="text-maintenance-v3 text-center mb-4">We're undergoing unexpected maintenance at the moment.  We’ll be back up and running again shortly.</p>
          <p class="text-maintenance-v3 text-center mb-2">If you need support or need to get updated, <a href="https://contacts.jaybod.com/home-2/contactcs/" class="blue-text-v3 text-maintenance-v3 text-maintenance-w-400">please contact us here</a></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from "../../../components/nav-login-registerV3.vue";
import appConfig from "@/app.config";

export default {
  page: {
    title: "maintenance",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: {
    Navbar
  }
}
</script>

<style>

</style>
